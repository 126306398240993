var _hmt = _hmt || [];

(function () {
  if (location.host === 'm.cheqianqiu.net') {
    // 百度统计
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?a64f738ec968d2a1e2ef29a6f4ffc0ad";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);

    // 百度推送
    var bp = document.createElement('script');
    var curProtocol = window.location.protocol.split(':')[0];
    if (curProtocol === 'https') {
      bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
    } else {
      bp.src = 'http://push.zhanzhang.baidu.com/push.js';
    }
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(bp, s);
  }
  // 导航
  const navEl = document.getElementById('nav')
  const menuList = navEl.querySelector('.menu-list')
  const navBg = navEl.querySelector('.nav-bg')
  const menuBtn = document.getElementById('menu-btn')
  const activeCls = 'active'
  const aniInCls = 'animate__fadeInRight'
  const aniOutCls = 'animate__fadeOutRight'
  const inFn = () => {
    menuList.classList.remove(aniOutCls)
    menuList.classList.add(aniInCls)
    navEl.classList.add(activeCls)
  }
  const outFn = () => {
    menuList.classList.remove(aniInCls)
    menuList.classList.add(aniOutCls)
    setTimeout(() => {
      navEl.classList.remove(activeCls)
    }, 200);
  }
  menuBtn.addEventListener('click', () => {
    if (navEl.classList.contains(activeCls)) {
      outFn()
    } else {
      inFn()
    }
  })
  navBg.addEventListener('touchstart', () => {
    outFn()
  })

  // 返回顶部
  const backTop = document.getElementById('back-top')

  let timer = null;
  if (backTop) {
    backTop.onclick = function () {
      cancelAnimationFrame(timer);
      //获取当前毫秒数
      let startTime = +new Date();
      //获取当前页面的滚动高度
      let b = document.body.scrollTop || document.documentElement.scrollTop;
      let d = 500;
      let c = b;
      timer = requestAnimationFrame(function func() {
        let t = d - Math.max(0, startTime - (+new Date()) + d);
        document.documentElement.scrollTop = document.body.scrollTop = t * (-c) / d + b;
        timer = requestAnimationFrame(func);
        if (t >= d) {
          cancelAnimationFrame(timer);
        }
      });
    }
  }

  // 禁止复制
  document.oncopy = e => {
    if (e.target.nodeName === 'TEXTAREA') {
      return true;
    }
    e.clipboardData.setData('text', '请尊重原创，反对抄袭！')
    return false
  }

  // 选择代办
  var serviceBtn = document.querySelector('.service-btn')
  var serviceDialog = document.querySelector('.service-dialog')
  if (serviceBtn && serviceDialog) {
    serviceBtn.addEventListener('click', function () {
      serviceDialog.classList.add('active')
    })

    serviceDialog.querySelectorAll('.dialog-bg,.close').forEach(function (item) {
      item.addEventListener('click', function () {
        serviceDialog.classList.remove('active')
      })
    })
  }
})();